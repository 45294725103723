import "./src/styles/global.scss";
import "@fontsource/prata";
import "@fontsource/roboto";
// import "@fontsource/roboto-condensed";
// import "@fontsource/philosopher";
import "aos/dist/aos.css";
import "sal.js/dist/sal.css";

import scrollSnapPolyfill from "css-scroll-snap-polyfill";
scrollSnapPolyfill();

// import "whatwg-fetch"; // require('whatwg-fetch') // if it's gatsby v2 - https://gatsby.app/no-mixed-modules
